import { type Reference } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import {
  useEnclaveConfigurationPinQuery,
  useRebaseSubmittedRequestMutation,
  useSubmittedRequestRebaseQuery,
} from "@decentriq/graphql/dist/hooks";
import { faSync } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Button } from "@mui/joy";
import { memo } from "react";
import { usePublishedDataRoom } from "contexts";
import { mapErrorToGeneralSnackbar, useDataRoomSnackbar } from "hooks";

interface SubmittedRequestRebaseProps {
  requestId: string;
}

const SubmittedRequestRebase: React.FC<SubmittedRequestRebaseProps> = memo(
  ({ requestId }) => {
    const { enqueueSnackbar } = useDataRoomSnackbar();
    const { user } = useAuth0();
    const {
      dcrHash,
      driverAttestationHash,
      dataRoomId,
      enableAutomergeFeature,
    } = usePublishedDataRoom();
    const {
      data: enclaveConfigurationPinData,
      loading: enclaveConfigurationPinLoading,
    } = useEnclaveConfigurationPinQuery({
      onError: (error) => {
        enqueueSnackbar(
          ...mapErrorToGeneralSnackbar(
            error,
            "Enclave configuration pin could not be retrieved."
          )
        );
      },
      variables: { dataRoomId },
    });
    const { publishedDataRoom } = enclaveConfigurationPinData || {};
    const { enclaveConfigurationPin } = publishedDataRoom || {};
    const [rebaseSubmittedRequest, { loading: rebasing }] =
      useRebaseSubmittedRequestMutation({
        onError: (error) => {
          enqueueSnackbar(
            ...mapErrorToGeneralSnackbar(error, `Request could not be rebased.`)
          );
        },
        update: (cache, { data }) => {
          cache.modify({
            fields: {
              submittedRequests(existingRefs, { canRead }) {
                cache.evict({
                  id: cache.identify({
                    __typename: "SubmittedDataRoomRequest",
                    id: requestId,
                  }),
                });
                cache.gc();
                return {
                  ...existingRefs,
                  nodes: [
                    ...(existingRefs?.nodes || []),
                    {
                      __ref: cache.identify({
                        __typename: "SubmittedDataRoomRequest",
                        id: data?.rebaseSubmittedDataRoomRequest.id,
                      }),
                    },
                  ].filter((ref: Reference) => canRead(ref)),
                };
              },
            },
            id: cache.identify({
              __typename: "PublishedDataRoom",
              id: dataRoomId,
            }),
          });
        },
      });
    const { data } = useSubmittedRequestRebaseQuery({
      onError: (error) => {
        enqueueSnackbar(
          ...mapErrorToGeneralSnackbar(error, `Unable to fetch request`)
        );
      },
      variables: {
        id: requestId,
      },
    });
    const hasData = !!data?.submittedDataRoomRequest;
    const needsRebase =
      hasData &&
      data!.submittedDataRoomRequest!.enclaveConfigurationPin !==
        enclaveConfigurationPin &&
      !enableAutomergeFeature;
    const isSubmitter =
      hasData && user?.email === data!.submittedDataRoomRequest!.owner.email;
    // const needsRebase = true;
    // const isSubmitter = true;
    return enclaveConfigurationPinLoading ? null : needsRebase ? (
      isSubmitter ? (
        <Alert
          color="warning"
          endDecorator={
            <Button
              disabled={rebasing}
              onClick={() =>
                rebaseSubmittedRequest({
                  variables: {
                    input: {
                      commitId: data!.submittedDataRoomRequest!.node!.commitId,
                      dcrHash,
                      driverAttestationHash,
                      id: data!.submittedDataRoomRequest!.id,
                    },
                  },
                })
              }
              startDecorator={
                <FontAwesomeIcon fixedWidth={true} icon={faSync} />
              }
            >
              Update
            </Button>
          }
        >
          Other computations have been integrated to the data clean room since
          this request has been created. Therefore, an update is required.
          <br />
          All affected Data Owners need to approve it again, before it can be
          integrated.
        </Alert>
      ) : (
        <Alert>
          Another request has been integrated to the data clean room prior to
          this request.
          <br />
          Please ask the submitter to access their Requests tab and re-submit
          it.
        </Alert>
      )
    ) : null;
  }
);
SubmittedRequestRebase.displayName = "SubmittedRequestRebase";

export default SubmittedRequestRebase;
