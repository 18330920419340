import { useAuth0 } from "@auth0/auth0-react";
import { useSubmittedRequestSigneesQuery } from "@decentriq/graphql/dist/hooks";
import { faCheck, faInfoCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Alert,
  Avatar,
  Badge,
  Chip,
  FormControl,
  FormLabel,
  List,
  ListItem,
  ListItemDecorator,
  Tooltip,
  Typography,
} from "@mui/joy";
import { memo } from "react";

interface SubmittedRequestSigneesProps {
  submittedRequestId: string;
}

const SubmittedRequestSignees: React.FC<SubmittedRequestSigneesProps> = memo(
  ({ submittedRequestId }) => {
    const { user } = useAuth0();
    const currentUserEmail = user?.email;
    const { data } = useSubmittedRequestSigneesQuery({
      variables: {
        id: submittedRequestId,
      },
    });
    const signees = data?.submittedDataRoomRequest?.signers?.nodes;
    return (
      <FormControl>
        <FormLabel>
          <Typography
            endDecorator={
              <Tooltip
                placement="top"
                sx={{ maxWidth: 300 }}
                title="All participants with permissions to provision datasets to dependencies of this computation are required to approve the request."
              >
                <FontAwesomeIcon icon={faInfoCircle} />
              </Tooltip>
            }
            level="inherit"
          >
            Affected data owners
          </Typography>
        </FormLabel>
        {signees?.length ? (
          <List>
            {signees.map((signee) => (
              <ListItem key={signee.id}>
                <ListItemDecorator>
                  {!signee.signature ? (
                    <Badge
                      badgeContent={
                        <FontAwesomeIcon icon={faCheck} size="xs" />
                      }
                      badgeInset="14%"
                      color="primary"
                      sx={{ "--Badge-paddingX": 0 }}
                    >
                      <Avatar size="sm">
                        {signee.userEmail.substring(0, 2).toUpperCase()}
                      </Avatar>
                    </Badge>
                  ) : (
                    <Avatar size="sm">
                      {signee.userEmail.substring(0, 2).toUpperCase()}
                    </Avatar>
                  )}
                </ListItemDecorator>
                <Typography
                  endDecorator={
                    signee.userEmail === currentUserEmail ? (
                      <strong>(You)</strong>
                    ) : null
                  }
                  level="inherit"
                >
                  {signee.userEmail}
                </Typography>
                <Chip>{signee.signature ? "Approved" : "Pending"}</Chip>
              </ListItem>
            ))}
          </List>
        ) : (
          <Alert>
            {signees
              ? "No dependencies yet"
              : "Please validate the computation to determine the affected data owners."}
          </Alert>
        )}
      </FormControl>
    );
  }
);
SubmittedRequestSignees.displayName = "SubmittedRequestSignees";

export default SubmittedRequestSignees;
