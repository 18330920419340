import { DqTable } from "@decentriq/components";
import { Alert, Box, CircularProgress, Stack } from "@mui/joy";
import { memo } from "react";
import {
  AddParticipantField,
  ComputeNodesPermissionsSelect,
  DataNodesPermissionsSelect,
  DeleteParticipantButton,
  ParticipantEmailField,
} from "components";
import { usePermissionsVars } from "contexts";
import useDataRoomParticipants from "./useDataRoomParticipants";

interface ParticipantsConstructorProps {
  id: string;
}

const ParticipantsConstructor: React.FC<ParticipantsConstructorProps> = memo(
  ({ id }) => {
    const {
      participants = [],
      isLoading = false,
      error,
    } = useDataRoomParticipants();
    const { canEditPermissions } = usePermissionsVars();
    if (isLoading) {
      return (
        <Box
          alignItems="center"
          display="flex"
          justifyContent="center"
          padding="1rem"
        >
          <CircularProgress sx={{ "--CircularProgress-size": "1.5rem" }} />
        </Box>
      );
    }
    if (error) {
      return (
        <Alert color="danger">
          Data clean room participants could not be retrieved. Please try again
          by refreshing the page.
        </Alert>
      );
    }
    return (
      <Stack>
        <DqTable
          columns={[
            {
              Cell: ({ cell }) => (
                <ParticipantEmailField participant={cell.getValue()} />
              ),
              Footer: canEditPermissions ? (
                <AddParticipantField
                  emails={participants.map((p) => p.userEmail)}
                  idForDataRoom={id}
                />
              ) : null,
              accessorFn: (row) => row,
              header: "Email",
              id: "email",
            },
            {
              Cell: ({ cell }) => (
                <DataNodesPermissionsSelect participant={cell.getValue()} />
              ),
              accessorFn: (row) => row,
              header: "Data owner of",
              id: "dataNodesPermissions",
            },
            {
              Cell: ({ cell }) => (
                <ComputeNodesPermissionsSelect participant={cell.getValue()} />
              ),
              accessorFn: (row) => row,
              header: "Analyst of",
              id: "computeNodesPermissions",
            },
          ]}
          data={participants}
          enableRowActions={true}
          enableTableFooter={true}
          localization={{
            noRecordsToDisplay:
              "This data clean room doesn't have any participants yet.",
          }}
          muiTableBodyCellProps={{
            sx: {
              "&:first-child": { pb: 0, pt: 0 },
              "&:not(:first-child)": { p: 0 },
            },
          }}
          muiTableFooterCellProps={{ sx: { p: 0 } }}
          muiTableFooterProps={{
            sx: { "& .MuiTableCell-footer": { fontWeight: "inherit" } },
          }}
          renderRowActions={({ row: { original: participant } }) => {
            return canEditPermissions
              ? [
                  <DeleteParticipantButton
                    idForParticipant={participant?.id}
                    key="delete"
                  />,
                ]
              : null;
          }}
        />
        {canEditPermissions ? (
          <Alert>
            Please double-check that all participants have a Decentriq Platform
            account. Invite external participants via the Admin interface.
          </Alert>
        ) : null}
      </Stack>
    );
  }
);
ParticipantsConstructor.displayName = "ParticipantsConstructor";

export default ParticipantsConstructor;
