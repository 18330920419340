import { useAuth0 } from "@auth0/auth0-react";
import { DqSortableAccordionGroup } from "@decentriq/components";
import { type PublishedParticipantPermission } from "@decentriq/graphql/dist/types";
import {
  accordionDetailsClasses,
  AccordionGroup,
  Box,
  CircularProgress,
  Divider,
  Stack,
} from "@mui/joy";
import { Alert } from "@mui/material";
import { Fragment, memo } from "react";
import { useComputeNodesVars, useDataRoom } from "contexts";
import { ComputeNodeConstructorMode } from "features";
import { ComputeNode, ComputeNodeCreator } from "features/computeNode";
import { useIsDataRoomOwner, useNodes } from "hooks";
import { ComputeNodeTypeNames } from "models";
import { ComputeNodesEmptyPanel, ComputeNodesToolbar } from "./components";

interface ComputeNodesProps {
  relaxed?: boolean;
}

const HAS_COMPUTE_NODE_CREATOR = true;

const ComputeNodes = memo<ComputeNodesProps>(({ relaxed }) => {
  const { dataRoomId, isPublished } = useDataRoom();
  const isOwner = useIsDataRoomOwner();
  const { user = {} } = useAuth0();
  const { readOnly, mode, permittedOnly } = useComputeNodesVars();
  const { email: currentUserEmail } = user || {};
  const { computeNodesOrder, error, loading, nodes, reorderComputeNodes } =
    useNodes();
  const computeNodes = nodes.filter(({ __typename }) =>
    (Object.values(ComputeNodeTypeNames) as string[]).includes(
      __typename as string
    )
  );
  const computeNodesIds = computeNodes
    .slice()
    .filter(({ permissions = [] }) => {
      // In draft mode all the nodes should be shown, even the ones for which permissions for a user are not set
      if (!isPublished) {
        return true;
      }
      // Nodes should be filtered by permission only in Action mode, so on the Overview tab all of them should be shown
      if (mode !== ComputeNodeConstructorMode.ACTION) {
        return true;
      }
      return (
        !permittedOnly ||
        (permissions as PublishedParticipantPermission[]).some(
          ({ participant }) => participant.userEmail === currentUserEmail
        )
      );
    })
    .map((computeNode) => computeNode?.id)
    .filter(Boolean)
    .sort(
      (a: string, b: string) =>
        computeNodesOrder.indexOf(a) - computeNodesOrder.indexOf(b)
    );
  if (loading && !computeNodesIds.length) {
    return (
      <Box
        alignItems="center"
        display="flex"
        justifyContent="center"
        padding="1rem"
      >
        <CircularProgress sx={{ "--CircularProgress-size": "1.5rem" }} />
      </Box>
    );
  }
  if (error) {
    return (
      <Alert severity="error">
        Data clean room computations could not be retrieved. Please try again by
        refreshing the page.
      </Alert>
    );
  }
  const ids = computeNodesIds;
  const isSortable = isOwner && !readOnly;
  const ThisGroup = isSortable ? DqSortableAccordionGroup : AccordionGroup;
  const groupProps = isSortable
    ? { ids: ids, onIdsSort: reorderComputeNodes }
    : {};
  return (
    <Stack>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        {HAS_COMPUTE_NODE_CREATOR && !readOnly ? (
          <ComputeNodeCreator dataRoomId={dataRoomId} />
        ) : null}
        <ComputeNodesToolbar dataRoomId={dataRoomId!} />
      </Box>
      {ids.length > 0 ? (
        <Box sx={relaxed ? {} : { overflow: "auto" }}>
          <ThisGroup
            disableDivider={true}
            sx={(theme) => ({
              "--List-radius": theme.radius.sm,
              borderRadius: theme.radius.sm,
              [`& .${accordionDetailsClasses.content}`]: {
                boxShadow: (theme) =>
                  `inset 0 1px ${theme.vars.palette.divider}`,
                [`&.${accordionDetailsClasses.expanded}`]: {
                  paddingBlock: "0.75rem",
                },
              },
            })}
            variant="outlined"
            {...groupProps}
          >
            {ids.map((id: string, index: number) => (
              <Fragment key={id}>
                <ComputeNode
                  computeNodeId={id}
                  draggable={isSortable}
                  {...Object.fromEntries(
                    [
                      index === 0 && "data-first-child",
                      index === ids.length - 1 && "data-last-child",
                    ]
                      .filter(Boolean)
                      .map((k) => [k, ""])
                  )}
                />
                {index === ids.length - 1 ? null : <Divider />}
              </Fragment>
            ))}
          </ThisGroup>
        </Box>
      ) : (
        <ComputeNodesEmptyPanel />
      )}
    </Stack>
  );
});

ComputeNodes.displayName = "ComputeNodes";

export default ComputeNodes;
