import { useDraftDataRoomUpdateEnableImmutabilityMutation } from "@decentriq/graphql/dist/hooks";
import { faInfoCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkbox, Tooltip } from "@mui/joy";
import { Fragment, memo, useCallback } from "react";
import { usePermissionsVars } from "contexts";
import { mapDraftDataRoomErrorToSnackbar, useDataRoomSnackbar } from "hooks";
import useDataRoomEnableImmutability from "./useDataRoomEnableImmutability";

interface EnableImmutabilityCheckboxProps {
  idForDataRoom: string;
}

const EnableImmutabilityCheckbox: React.FC<EnableImmutabilityCheckboxProps> =
  memo(({ idForDataRoom }) => {
    const { canEditPermissions } = usePermissionsVars();
    const { enqueueSnackbar } = useDataRoomSnackbar();
    const [draftDataRoomUpdateEnableImmutability, { loading: isMutating }] =
      useDraftDataRoomUpdateEnableImmutabilityMutation({
        onError: (error) => {
          enqueueSnackbar(
            ...mapDraftDataRoomErrorToSnackbar(
              error,
              "DCR immutability settings could not be updated."
            )
          );
        },
      });
    const onChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        draftDataRoomUpdateEnableImmutability({
          variables: {
            id: idForDataRoom,
            mutable: !event.target.checked,
          },
        });
      },
      [draftDataRoomUpdateEnableImmutability, idForDataRoom]
    );
    const checked = useDataRoomEnableImmutability();
    const disabled = isMutating || !canEditPermissions;
    return (
      <Checkbox
        checked={checked}
        disabled={disabled}
        label={
          <Fragment>
            This data clean room is immutable
            <span style={{ verticalAlign: "middle", whiteSpace: "nowrap" }}>
              &nbsp;
              <Tooltip
                placement="top"
                sx={{ maxWidth: 300 }}
                title="Once it is published, participants will not be able to submit any request for modifications in the data clean room."
              >
                <FontAwesomeIcon icon={faInfoCircle} />
              </Tooltip>
            </span>
          </Fragment>
        }
        onChange={onChange}
        slotProps={{
          label: { sx: { cursor: "pointer", userSelect: "none", zIndex: 1 } },
        }}
      />
    );
  });
EnableImmutabilityCheckbox.displayName = "EnableImmutabilityCheckbox";

export default EnableImmutabilityCheckbox;
