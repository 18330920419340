import { useAuth0 } from "@auth0/auth0-react";
import { useDevComputeNodesQuery } from "@decentriq/graphql/dist/hooks";
import {
  accordionDetailsClasses,
  AccordionGroup,
  Box,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/joy";
import { Fragment } from "react";
import { usePublishedDataRoom } from "contexts";
import { ComputeNode } from "features/computeNode";
import { DevComputeNodeProvider } from "features/devComputeNodes/contexts/devComputeNode";
import PlaygroundCreator from "../PlaygroundCreator/PlaygroundCreator";

interface DevComputeNodesProps {
  dataRoomId: string;
  switchToRequests: () => void;
}

const DevComputeNodes: React.FC<DevComputeNodesProps> = ({ dataRoomId }) => {
  const { testing } = usePublishedDataRoom();
  const { data, loading } = useDevComputeNodesQuery({
    variables: { id: dataRoomId },
  });
  const { user } = useAuth0();
  const computeNodesIds =
    data?.publishedDataRoom?.playgrounds?.nodes
      ?.filter((n) => n.owner.email === user?.email)
      ?.map((n) => ({
        computeNodeId: n.draftNode.id,
        playgroundId: n.id,
      })) || [];
  if (loading) {
    return (
      <Box
        alignItems="center"
        display="flex"
        height="100%"
        justifyContent="center"
        padding="1rem"
      >
        <CircularProgress sx={{ "--CircularProgress-size": "1.5rem" }} />
      </Box>
    );
  }
  return (
    <Stack>
      {testing ? (
        <Fragment>
          <Typography level="title-md">Development in Test mode</Typography>
          <Typography level="body-md">
            Use this environment to create and run arbitrary computations based
            on test datasets provisioned to any element of this data clean room,
            regardless of the permissions.
            <br />
            These computations are only visible to you and are not integrated
            into the data clean room unless you leave the Test mode and create a
            new request.
          </Typography>
        </Fragment>
      ) : (
        <Fragment>
          <Typography level="title-md">Development environment</Typography>
          <Typography level="body-md">
            Use this environment to create and run arbitrary computations based
            on data and results you already have permission for.
            <br />
            These computations are only visible to you and are not integrated
            into the data clean room unless you create a request.
          </Typography>
        </Fragment>
      )}
      <Stack direction="row">
        <PlaygroundCreator dataRoomId={dataRoomId} />
      </Stack>
      {computeNodesIds.length > 0 ? (
        <AccordionGroup
          sx={(theme) => ({
            "--List-radius": theme.radius.sm,
            borderRadius: theme.radius.sm,
            [`& .${accordionDetailsClasses.content}`]: {
              boxShadow: (theme) => `inset 0 1px ${theme.vars.palette.divider}`,
              [`&.${accordionDetailsClasses.expanded}`]: {
                paddingBlock: "0.75rem",
              },
            },
          })}
          variant="outlined"
        >
          {computeNodesIds.map(({ computeNodeId, playgroundId }, index) => {
            return (
              <DevComputeNodeProvider
                key={computeNodeId}
                value={{ playgroundId }}
              >
                <ComputeNode
                  computeNodeId={computeNodeId}
                  {...Object.fromEntries(
                    [
                      index === 0 && "data-first-child",
                      index === computeNodesIds.length - 1 && "data-last-child",
                    ]
                      .filter(Boolean)
                      .map((k) => [k, ""])
                  )}
                />
              </DevComputeNodeProvider>
            );
          })}
        </AccordionGroup>
      ) : null}
    </Stack>
  );
};

export default DevComputeNodes;
