import { useApolloClient } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import {
  DqCreateMeasurementDcrDialog,
  type DqCreateMeasurementDcrFormProviderSchemaType,
  DqCreateMediaDcrDialog,
} from "@decentriq/components";
import {
  useMediaInsightsInviteParticipantsMutation,
  useSendCollaborationRequestToDataPartnerMutation,
  useSendCollaborationRequestToPublisherMutation,
} from "@decentriq/graphql/dist/hooks";
import { MyDataRoomsDocument } from "@decentriq/graphql/dist/types";
import { Portal } from "@mui/material";
import { useBoolean, useSafeState } from "ahooks";
import { memo, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DataScienceDataRoomCreateDialog } from "features/dataScienceDataRoom";
import { useMeasurement } from "features/measurementDcr/components/CreateMeasurementDcr/hooks";
import { type PublishMediaInsightsDcrInput } from "features/MediaInsightsDcr";
import usePublishMediaInsightsDcr from "features/MediaInsightsDcr/components/MediaInsightsDcrCreateDialog/usePublishMediaInsightsDcr";
import useValidateMediaDataRoom from "features/MediaInsightsDcr/components/MediaInsightsDcrCreateDialog/useValidateMediaDataRoom";
import {
  CommonSnackbarOrigin,
  mapErrorToGeneralSnackbar,
  useCreateDataRoom,
  useGeneralSnackbar,
  useOrganizationPreferences,
} from "hooks";
import { dataRoomPathBuilder, DataRoomType, DataRoomTypeNames } from "models";
import { delay, getEffectiveErrorMessage, noSoonerThan } from "utils";
import DataRoomTypeSelectDialog from "../DataRoomTypeSelectDialog/DataRoomTypeSelectDialog";

interface DataRoomCreateDialogProps {
  renderTrigger: (open: () => void) => React.ReactNode;
}

const DataRoomCreateDialog: React.FC<DataRoomCreateDialogProps> = memo(
  ({ renderTrigger }) => {
    const [
      isTypeSelectionOpen,
      { setTrue: openTypeSelection, setFalse: closeTypeSelection },
    ] = useBoolean(false);
    const { user = {} } = useAuth0();
    const { email: currentUserEmail = "" } = user || {};
    const [dataRoomType, setDataRoomType] = useState<DataRoomType | null>(null);
    const {
      createDataRoom: createDataScienceDataRoom,
      isCreateDataRoomLoading: isCreateDataScienceDataRoomLoading,
    } = useCreateDataRoom(currentUserEmail, {
      onCompleted: () => setDataRoomType(null),
      onError: () => setDataRoomType(null),
    });
    const {
      isAllowedToCreateDataRoom,
      hasAnalyticsFeatures: canViewAnalyticsDataRoom,
      canViewMediaDataRoom,
      canViewMeasurements: canViewMeasurementDataRoom,
      numberOfAvailableDataRoomTypes,
    } = useOrganizationPreferences();
    // Open proper modal window depending on allowance selected in Admin for a specific organization
    useEffect(() => {
      if (!isTypeSelectionOpen || !isAllowedToCreateDataRoom) {
        closeTypeSelection();
        return;
      }
      if (
        canViewAnalyticsDataRoom &&
        !(canViewMediaDataRoom || canViewMeasurementDataRoom)
      ) {
        setDataRoomType(DataRoomType.DataScience);
        closeTypeSelection();
      }
      if (
        canViewMediaDataRoom &&
        !(canViewAnalyticsDataRoom || canViewMeasurementDataRoom)
      ) {
        setDataRoomType(DataRoomType.MediaInsights);
        closeTypeSelection();
      }
      if (
        canViewMeasurementDataRoom &&
        !(canViewAnalyticsDataRoom || canViewMediaDataRoom)
      ) {
        setDataRoomType(DataRoomType.Measurement);
        closeTypeSelection();
      }
    }, [
      isTypeSelectionOpen,
      closeTypeSelection,
      isAllowedToCreateDataRoom,
      canViewAnalyticsDataRoom,
      canViewMediaDataRoom,
      canViewMeasurementDataRoom,
    ]);
    const handleDataRoomTypeSetting = useCallback(
      (type: DataRoomType | null) => {
        setDataRoomType(type);
        if (type !== null) {
          closeTypeSelection();
        }
      },
      [setDataRoomType, closeTypeSelection]
    );
    const handleCancel = useCallback(
      () => handleDataRoomTypeSetting(null),
      [handleDataRoomTypeSetting]
    );
    const restartCreation = useCallback(() => {
      handleCancel();
      if (numberOfAvailableDataRoomTypes < 2) {
        return;
      }
      openTypeSelection();
    }, [handleCancel, numberOfAvailableDataRoomTypes, openTypeSelection]);
    // Publish Measurement DCR
    const navigate = useNavigate();
    const { enqueueSnackbar } = useGeneralSnackbar({
      origin: CommonSnackbarOrigin.DASHBOARD,
    });
    const { publish } = useMeasurement();
    const onSubmit = useCallback(
      async (payload: DqCreateMeasurementDcrFormProviderSchemaType) => {
        try {
          const id = await publish({ payload });
          const dataRoomLink = dataRoomPathBuilder(
            id,
            DataRoomTypeNames.PublishedDataRoom
          );
          navigate(dataRoomLink);
        } catch (error) {
          enqueueSnackbar(
            ...mapErrorToGeneralSnackbar(
              error,
              "Error creating data clean room"
            )
          );
        }
      },
      [enqueueSnackbar, navigate, publish]
    );
    // Publish Media DCR
    const onCancel = handleCancel;
    // NOTE: Below is an exact copy of src/features/MediaInsightsDcr/components/MediaInsightsDcrCreateDialog/MediaInsightsDcrCreateDialog.tsx
    const apolloClient = useApolloClient();
    const [publishing, setPublishing] = useSafeState(false);
    const [publishingCalled, setPublishingCalled] = useSafeState(false);
    const [publishingError, setPublishingError] = useSafeState<
      unknown | undefined
    >();
    const [mediaInviteParticipants] =
      useMediaInsightsInviteParticipantsMutation({
        onError: (error) => {
          enqueueSnackbar(
            "Data clean room participants could not be invited.",
            {
              context: error?.message,
              persist: true,
              variant: "error",
            }
          );
        },
      });
    const [sendCollaborationRequestToPublisherMutation] =
      useSendCollaborationRequestToPublisherMutation();
    const [sendCollaborationRequestToDataPartnerMutation] =
      useSendCollaborationRequestToDataPartnerMutation();
    const sendCollaborationRequest = useCallback(
      async ({
        requestRecipientId,
        message,
        receiver,
      }: {
        requestRecipientId: string;
        message: string;
        receiver: "publisher" | "dataPartner";
      }) => {
        const mutation =
          receiver === "publisher"
            ? sendCollaborationRequestToPublisherMutation
            : sendCollaborationRequestToDataPartnerMutation;
        await mutation({
          onCompleted: () => {
            enqueueSnackbar("Contact request sent");
            onCancel();
          },
          onError: (error) => {
            enqueueSnackbar("Contact request could not be sent", {
              context: error?.message,
              persist: true,
              variant: "error",
            });
          },
          variables: {
            message,
            requestRecipientId,
          },
        });
      },
      [
        enqueueSnackbar,
        onCancel,
        sendCollaborationRequestToPublisherMutation,
        sendCollaborationRequestToDataPartnerMutation,
      ]
    );
    const { publish: publishMediaInsightsDcr } = usePublishMediaInsightsDcr();
    const { validateMediaInsightsDcr } = useValidateMediaDataRoom();
    const submit = useCallback(
      async (mediaDataRoomInput: PublishMediaInsightsDcrInput) => {
        const isMediaDataRoomValid =
          validateMediaInsightsDcr(mediaDataRoomInput);
        if (!isMediaDataRoomValid) {
          return;
        }
        setPublishing(true);
        setPublishingCalled(true);
        setPublishingError(undefined);
        try {
          await delay(1000);
          const { id: dataRoomId, driverAttestationHash } = await noSoonerThan(
            1000,
            publishMediaInsightsDcr(mediaDataRoomInput)
          );
          await noSoonerThan(
            1000,
            mediaInviteParticipants({
              variables: {
                input: {
                  dataRoomDescription: "",
                  publishedDataRoomDriverAttestationHash: driverAttestationHash,
                  publishedDataRoomEnclaveId: dataRoomId,
                },
              },
            })
          );
          await delay(1000);
          onCancel();
          await apolloClient.query({
            errorPolicy: "ignore",
            fetchPolicy: "network-only",
            query: MyDataRoomsDocument,
          });
          navigate(
            dataRoomPathBuilder(
              dataRoomId,
              DataRoomTypeNames.PublishedMediaInsightsDcr
            )
          );
        } catch (error) {
          enqueueSnackbar(`Data clean room could not be published.`, {
            context: getEffectiveErrorMessage(error),
            persist: true,
            variant: "error",
          });
          setPublishingError(error);
        } finally {
          setPublishing(false);
        }
      },
      [
        validateMediaInsightsDcr,
        publishMediaInsightsDcr,
        enqueueSnackbar,
        mediaInviteParticipants,
        onCancel,
        setPublishing,
        setPublishingCalled,
        setPublishingError,
        navigate,
        apolloClient,
      ]
    );
    return (
      <>
        {renderTrigger(openTypeSelection)}
        <Portal>
          <>
            <DataRoomTypeSelectDialog
              onCancel={closeTypeSelection}
              onConfirm={handleDataRoomTypeSetting}
              open={isTypeSelectionOpen}
            />
            <DataScienceDataRoomCreateDialog
              loading={isCreateDataScienceDataRoomLoading}
              onCancel={handleCancel}
              onConfirm={createDataScienceDataRoom}
              open={dataRoomType === DataRoomType.DataScience}
              restartCreation={restartCreation}
            />
            <DqCreateMediaDcrDialog
              onCancel={handleCancel}
              open={dataRoomType === DataRoomType.MediaInsights}
              publishing={publishing}
              publishingCalled={publishingCalled}
              publishingError={publishingError}
              restartCreation={restartCreation}
              sendCollaborationRequest={sendCollaborationRequest}
              submit={submit}
            />
            <DqCreateMeasurementDcrDialog
              onClose={handleCancel}
              onSubmit={onSubmit}
              open={dataRoomType === DataRoomType.Measurement}
            />
          </>
        </Portal>
      </>
    );
  }
);

export default DataRoomCreateDialog;
