import { type ApolloError } from "@apollo/client";
import {
  DqSortableAccordion,
  DqSortableAccordionGroup,
  DqSortableAccordionSummary,
} from "@decentriq/components";
import { useSetDataNodesOrderMutation } from "@decentriq/graphql/dist/hooks";
import { testIds } from "@decentriq/utils";
import {
  Accordion,
  AccordionDetails,
  accordionDetailsClasses,
  AccordionGroup,
  AccordionSummary,
  Box,
  CircularProgress,
  Divider,
  Stack,
  Typography,
} from "@mui/joy";
import { Alert } from "@mui/material";
import { useSelections } from "ahooks";
import { Fragment, useCallback, useMemo } from "react";
import { DatasetConstraints } from "features/computeNode";
import { DataNodeConstructorMode } from "features/dataNodes/models";
import { useIsDataRoomOwner } from "hooks";
import { type HooksNode } from "hooks/useNodes/useNodes";
import { type DataRoomData, type DataRoomDataTable } from "models";
import { useUniqueness } from "../../hooks";
import { type DataNodeChangeOutcomeType } from "../DataNodeWithTestDataChangedDialog/DataNodeWithTestDataChangedDialog";
import DataNodeAddNewField from "./DataNodeAddNewField";
import DataNodeChangeOutcomeHandler, {
  useDataNodeChangeOutcomeHandler,
} from "./DataNodeChangeOutcomeHandler";
import { type DataRoomDataNodeActionsRenderer } from "./DataNodeConstructorModels";
import {
  DataNodeConstructorParamsConsumer,
  DataNodeConstructorParamsWrapper,
  useDataNodeConstructorParams,
} from "./DataNodeConstructorParamsWrapper";
import DataNodeEditableTile from "./DataNodeEditableTile";
import DataNodeTile from "./DataNodeTile";
import { TableNodeColumnConstructor } from "./TableNodeColumnConstructor";

export * from "./DataNodeConstructorModels";

interface SharedProps {
  renderDataActions?: DataRoomDataNodeActionsRenderer;
  toggle: (id: string) => void;
}

interface DataNodeItemProps extends SharedProps {
  dataNode: DataRoomData;
  isExpanded: boolean;
  otherNodeNames: string[];
}

interface DataNodeListProps extends SharedProps {
  dataNodes: DataRoomData[];
  dataNodesOrder: string[];
  dataRoomId: string;
  isSelected: (id: string) => boolean;
  nodes: HooksNode[];
}

function DataNodeItem({
  dataNode,
  isExpanded,
  renderDataActions,
  otherNodeNames,
  toggle,
  ...rest
}: DataNodeItemProps) {
  const { id, isRequired } = dataNode;
  const { handleChangeOutcome } = useDataNodeChangeOutcomeHandler();
  const { readOnly } = useDataNodeConstructorParams();
  const {
    uniqueColumnIds,
    isLoading,
    draftTableLeafNodeUpdateUniquenessMutation,
  } = useUniqueness(id);
  const updateUniqueColumnIds = useCallback(
    async (uniqueColumnIds: string[][]) => {
      await draftTableLeafNodeUpdateUniquenessMutation({
        variables: {
          id,
          uniqueness: {
            value: {
              uniqueColumnIds,
            },
          },
        },
      });
    },
    [draftTableLeafNodeUpdateUniquenessMutation, id]
  );
  const isDraggable = !readOnly;
  const [ThisAccordion, ThisAccordionSummary] = isDraggable
    ? [DqSortableAccordion, DqSortableAccordionSummary]
    : [Accordion, AccordionSummary];
  return (
    <Fragment>
      <ThisAccordion defaultExpanded={isExpanded} id={id} {...rest}>
        <ThisAccordionSummary
          data-testid={testIds.dataNode.dataNodeCreator.toggleEditor}
          id={id}
          slotProps={{ indicator: { sx: { order: 1 } } }}
        >
          {readOnly ? (
            <DataNodeTile
              dataNode={dataNode}
              isCollapsed={!isExpanded}
              onToggleCollapsion={toggle}
              renderDataActions={renderDataActions}
            />
          ) : (
            <DataNodeEditableTile
              dataNode={dataNode}
              dataTestid={testIds.dataNode.dataNodeEditableTile.nameInput}
              hasError={false} // TODO: pass actual error
              helperTestid={
                testIds.dataNode.dataNodeEditableTile.nameInputHelper
              }
              isCollapsed={!isExpanded}
              isLoading={false}
              onChangeOutcome={() =>
                handleChangeOutcome("deleted", { dataNode })
              }
              onToggleCollapsion={toggle}
              otherNodeNames={otherNodeNames}
              renderDataActions={renderDataActions}
            />
          )}
        </ThisAccordionSummary>
        <AccordionDetails>
          <Stack>
            {dataNode.dataType === "table" && (
              <TableNodeColumnConstructor
                columns={(dataNode as DataRoomDataTable).columns}
                columnsOrder={(dataNode as DataRoomDataTable).columnsOrder}
                isLoading={isLoading}
                onChangeOutcome={(columnAdded, columnId) =>
                  handleChangeOutcome(
                    (columnAdded
                      ? "columnAdded"
                      : "changed") as DataNodeChangeOutcomeType,
                    { columnId, dataNode }
                  )
                }
                tableNodeId={id}
                uniqueColumnIds={uniqueColumnIds}
                updateUniqueColumnIds={updateUniqueColumnIds}
              />
            )}
            <DatasetConstraints
              dataType={dataNode.dataType}
              disabled={false}
              id={id}
              readOnly={readOnly}
              value={isRequired}
            />
            {dataNode.dataType === "unstructured" ? (
              <Typography level="body-md">
                Unstructured data files of any kind can be provisioned (e.g.
                JSON, ZIP, TXT)
              </Typography>
            ) : null}
          </Stack>
        </AccordionDetails>
      </ThisAccordion>
    </Fragment>
  );
}

function DataNodeList({
  dataNodes,
  dataNodesOrder,
  dataRoomId,
  isSelected,
  nodes,
  renderDataActions,
  toggle,
}: DataNodeListProps) {
  const dataNodeIds = useMemo(
    () =>
      dataNodes
        .slice()
        .sort(
          (a, b) => dataNodesOrder.indexOf(a.id) - dataNodesOrder.indexOf(b.id)
        )
        .map((dataNode) => dataNode.id),
    [dataNodes, dataNodesOrder]
  );
  const [setDataRoomDataNodesOrder] = useSetDataNodesOrderMutation();
  const ids = dataNodeIds;
  const reorderDataNodes = useCallback(
    (dataNodesOrder: string[]) => {
      setDataRoomDataNodesOrder({
        optimisticResponse: {
          __typename: "Mutation",
          draftDataRoom: {
            __typename: "DraftDataRoomMutations",
            setDataNodesOrder: {
              __typename: "DraftDataRoom",
              dataNodesOrder,
              id: dataRoomId,
            },
          },
        },
        variables: {
          dataNodesOrder,
          dataRoomId,
        },
      });
    },
    [dataRoomId, setDataRoomDataNodesOrder]
  );
  const { readOnly } = useDataNodeConstructorParams();
  const isOwner = useIsDataRoomOwner();
  const isSortable = isOwner && !readOnly;
  const ThisGroup = isSortable ? DqSortableAccordionGroup : AccordionGroup;
  const groupProps = isSortable
    ? { ids: ids, onIdsSort: reorderDataNodes }
    : {};
  return (
    <ThisGroup
      disableDivider={true}
      sx={(theme) => ({
        "--List-radius": theme.radius.sm,
        borderRadius: theme.radius.sm,
        [`& .${accordionDetailsClasses.content}`]: {
          boxShadow: (theme) => `inset 0 1px ${theme.vars.palette.divider}`,
          [`&.${accordionDetailsClasses.expanded}`]: {
            paddingBlock: "0.75rem",
          },
        },
      })}
      variant="outlined"
      {...groupProps}
    >
      {ids.map((id: string, index: number) => {
        const dataNode = dataNodes.find(
          ({ id: dataNodeId }) => dataNodeId === id
        );
        const otherNodeNames = nodes
          .filter(({ id: dataNodeId }) => dataNodeId !== id)
          .map(({ name }) => name);
        return dataNode ? (
          <Fragment key={id}>
            <DataNodeItem
              dataNode={dataNode}
              isExpanded={isSelected(dataNode.id)}
              otherNodeNames={otherNodeNames}
              renderDataActions={renderDataActions}
              toggle={toggle}
              {...Object.fromEntries(
                [
                  index === 0 && "data-first-child",
                  index === ids.length - 1 && "data-last-child",
                ]
                  .filter(Boolean)
                  .map((k) => [k, ""])
              )}
            />
            {index === ids.length - 1 ? null : <Divider />}
          </Fragment>
        ) : null;
      })}
    </ThisGroup>
  );
}

const emptyConstructorStatusMap = new Map<DataNodeConstructorMode, string>([
  [
    DataNodeConstructorMode.READONLY,
    "This data clean room doesn't have any data nodes declared.",
  ],
  [
    DataNodeConstructorMode.STATUS,
    "This data clean room doesn't have any data nodes declared.",
  ],
  [DataNodeConstructorMode.ACTION, "There is no data available to you."],
  [
    DataNodeConstructorMode.DEGRADE_ACTION,
    "You are not allowed to delete data.",
  ],
]);

interface DataNodeConstructorProps {
  dataNodes: DataRoomData[];
  dataNodesOrder: string[];
  dataRoomId: string;
  error?: ApolloError;
  loading?: boolean;
  mode: DataNodeConstructorMode;
  nodes: HooksNode[];
  renderDataActions?: DataRoomDataNodeActionsRenderer;
}

const DataNodeConstructor: React.FC<DataNodeConstructorProps> = (
  {
    dataNodes,
    dataNodesOrder,
    dataRoomId,
    error,
    loading,
    mode,
    nodes,
    renderDataActions,
  },
  ref
) => {
  const indexes = useMemo(() => dataNodes.map((t) => t.id), [dataNodes]);
  const { isSelected, toggle } = useSelections(indexes, []);
  const computeNodeNamesAndTypes = useMemo(() => {
    return dataNodes.map(({ name, dataType }) => ({
      dataType,
      name,
    }));
  }, [dataNodes]);
  if (loading && !dataNodes.length) {
    return (
      <Box
        alignItems="center"
        display="flex"
        justifyContent="center"
        padding="1rem"
      >
        <CircularProgress sx={{ "--CircularProgress-size": "1.5rem" }} />
      </Box>
    );
  }
  if (error) {
    return (
      <Alert severity="error">
        Data clean room data nodes could not be retrieved. Please try again by
        refreshing the page.
      </Alert>
    );
  }
  return (
    <DataNodeConstructorParamsWrapper mode={mode}>
      <Stack>
        <DataNodeConstructorParamsConsumer>
          {({ readOnly }) =>
            readOnly ? undefined : (
              <Stack direction="row">
                <DataNodeAddNewField
                  computeNodeNamesAndTypes={computeNodeNamesAndTypes}
                  toggle={toggle}
                />
              </Stack>
            )
          }
        </DataNodeConstructorParamsConsumer>
        <DataNodeChangeOutcomeHandler>
          {dataNodes.length > 0 && (
            <DataNodeList
              dataNodes={dataNodes}
              dataNodesOrder={dataNodesOrder}
              dataRoomId={dataRoomId}
              isSelected={isSelected}
              nodes={nodes}
              renderDataActions={renderDataActions}
              toggle={toggle}
            />
          )}
        </DataNodeChangeOutcomeHandler>
        {!dataNodes.length && emptyConstructorStatusMap.get(mode) ? (
          <Typography level="body-md" sx={{ mb: 1 }}>
            {emptyConstructorStatusMap.get(mode)}
          </Typography>
        ) : undefined}
      </Stack>
    </DataNodeConstructorParamsWrapper>
  );
};

DataNodeConstructor.displayName = "DataNodeConstructor";

export default DataNodeConstructor;
