import { SANITIZE_IDENTIFIER_INPUT } from "constants/index";
import { InlineEditor } from "@decentriq/components";
import {
  faClone,
  faColumns3,
  faRepeat,
  faTrashCan,
  faTriangleExclamation,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ButtonGroup,
  CircularProgress,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/joy";
import { orange } from "@mui/material/colors";
import { Fragment, useCallback } from "react";
import { DataRoomParticipantAvatarGroup } from "components";
import { useDataNodeActions } from "features/dataNodes/containers/DataNodes/DataNodesActionsWrapper";
import { type DataRoomData, type DataRoomDataTable } from "models";
import { isValidIdentifier, sanitizeIdentifier } from "utils/validation";
import { useDataNodeConstructorParams } from "./DataNodeConstructorParamsWrapper";

interface DataNodeEditableTileProps {
  dataNode: DataRoomData;
  dataTestid?: string;
  hasError: boolean;
  helperTestid?: string;
  isCollapsed: boolean;
  isLoading: boolean;
  onChangeOutcome?: () => void;
  onToggleCollapsion: (dataNodeId: string) => void;
  otherNodeNames: string[];
  renderDataActions?: (dataNode: DataRoomData) => React.ReactNode;
}

const getDuplicatedNodeName = (
  nodeName: string,
  nodeNames: string[]
): string => {
  const nextName = `Copy of ${nodeName}`;
  if (!nodeNames.includes(nextName)) {
    return nextName;
  }
  return getDuplicatedNodeName(nextName, nodeNames);
};

const DataNodeEditableTile: React.FC<DataNodeEditableTileProps> = ({
  dataNode,
  dataTestid,
  hasError,
  helperTestid,
  isCollapsed,
  isLoading,
  onChangeOutcome,
  onToggleCollapsion,
  otherNodeNames,
  renderDataActions,
}) => {
  const { withUploading, withDataDeletion, withDatasetStatus } =
    useDataNodeConstructorParams();
  const {
    handleDelete: deleteNode,
    handleNameUpdate,
    handleDuplicate,
  } = useDataNodeActions();
  const { name, id, dataType } = dataNode;
  const handleDelete = useCallback(
    async (node: DataRoomData) => deleteNode(node).then(onChangeOutcome),
    [deleteNode, onChangeOutcome]
  );
  return (
    <Fragment>
      <Stack alignItems="center" direction="row" sx={{ flex: 1, order: 2 }}>
        <InlineEditor
          autoFocus={true}
          cancelEditingButtonEnabled={false}
          dataTestid={dataTestid}
          helperTestid={helperTestid}
          onChange={(value: string) => {
            handleNameUpdate({
              dataType,
              id,
              name: SANITIZE_IDENTIFIER_INPUT
                ? sanitizeIdentifier(value)
                : value,
            });
          }}
          placeholder="Name"
          readOnly={false}
          saveEditingButtonEnabled={false}
          saveEditingOnClickAway={true}
          validate={(value: string) => {
            return !isValidIdentifier(value)
              ? "Identifiers should begin with a letter, not end in an underscore, and should contain only alphanumeric characters or spaces"
              : otherNodeNames.includes(value)
                ? "Name must be unique across all data and compute nodes"
                : value.length > 100
                  ? "Table name must be less than 100 characters"
                  : !value
                    ? "Table name must be set"
                    : "";
          }}
          value={name}
        />
      </Stack>
      <Stack alignItems="center" direction="row" sx={{ order: 3 }}>
        {dataNode.dataType === "table" && (
          <Typography
            fontWeight={400}
            level="body-md"
            noWrap={true}
            startDecorator={
              (dataNode as DataRoomDataTable).columns.length === 0 ? (
                <FontAwesomeIcon
                  color={orange[400]}
                  fixedWidth={true}
                  icon={faTriangleExclamation}
                  style={{ marginRight: 4 }}
                />
              ) : (
                <FontAwesomeIcon icon={faColumns3} />
              )
            }
          >
            {(dataNode as DataRoomDataTable).columns.length} column
            {(dataNode as DataRoomDataTable).columns.length !== 1 ? "s" : ""}
          </Typography>
        )}
        <DataRoomParticipantAvatarGroup
          participants={dataNode.participants.map(
            ({ userEmail: name, uploadedAt }) => ({
              active: !!uploadedAt && withDatasetStatus,
              name,
            })
          )}
        />
        {hasError && !isLoading && (
          <IconButton type="submit">
            <FontAwesomeIcon fixedWidth={true} icon={faRepeat} />
          </IconButton>
        )}
        {isLoading ? (
          <CircularProgress
            sx={{ "--CircularProgress-size": "16px", marginRight: "8px" }}
          />
        ) : (
          <ButtonGroup>
            <Tooltip id="button-duplicate" placement="top" title="Duplicate">
              <IconButton
                disabled={isLoading}
                onClick={() =>
                  handleDuplicate({
                    ...dataNode,
                    name: getDuplicatedNodeName(dataNode.name, otherNodeNames),
                  })
                }
              >
                <FontAwesomeIcon fixedWidth={true} icon={faClone} />
              </IconButton>
            </Tooltip>
            <Tooltip id="button-delete" placement="top" title="Delete">
              <IconButton
                disabled={isLoading}
                onClick={() => handleDelete(dataNode)}
              >
                <FontAwesomeIcon fixedWidth={true} icon={faTrashCan} />
              </IconButton>
            </Tooltip>
          </ButtonGroup>
        )}
        {(withUploading || withDataDeletion) && renderDataActions
          ? renderDataActions(dataNode)
          : null}
      </Stack>
    </Fragment>
  );
};

export default DataNodeEditableTile;
