import {
  useRequestsQuery,
  useSubmittedDataRoomRequestsQuery,
} from "@decentriq/graphql/dist/hooks";
import {
  accordionDetailsClasses,
  AccordionGroup,
  Skeleton,
  Stack,
  Typography,
} from "@mui/joy";
import { Fragment, useMemo } from "react";
import useDataRoomEnableImmutability from "components/entities/dataRoomParticipants/EnableImmutabilityCheckbox/useDataRoomEnableImmutability";
import { RequestProvider } from "contexts";
import { RequestsVarsWrapper, SubmittedRequestsVarsWrapper } from "wrappers";
import RequestCreatorPlaceholder from "../RequestCreatorPlaceholder/RequestCreatorPlaceholder";
import RequestItem from "../RequestItem/RequestItem";

interface RequestsProps {
  dataRoomId: string;
  defaultExpandedRequests?: string[];
  defaultExpandedComputations?: string[];
}

const Requests: React.FC<RequestsProps> = ({
  dataRoomId,
  defaultExpandedRequests,
  defaultExpandedComputations,
}) => {
  const hasDevTab = !useDataRoomEnableImmutability();
  const { data: requestsData, loading: requestsLoading } = useRequestsQuery({
    fetchPolicy: "network-only",
    variables: { id: dataRoomId },
  });
  const { data: submittedRequestsData, loading: submittedRequestsLoading } =
    useSubmittedDataRoomRequestsQuery({
      variables: { id: dataRoomId },
    });
  const requests = useMemo(
    () =>
      requestsData?.publishedDataRoom?.requests?.nodes?.map((n) => ({
        computeNodeId: n.draftNode.id,
        requestId: n.id,
      })) || [],
    [requestsData]
  );
  const requestsIds = useMemo(
    () => requests.map((r) => r.requestId),
    [requests]
  );
  const submittedRequests = useMemo(
    () =>
      submittedRequestsData?.publishedDataRoom?.submittedRequests?.nodes?.map(
        (n) => ({
          commitId: n.node.commitId,
          computeNodeId: n.node.id,
          requestId: n.id,
        })
      ) || [],
    [submittedRequestsData]
  );
  const submittedRequestsIds = useMemo(
    () => submittedRequests.map((r) => r.requestId),
    [submittedRequests]
  );
  const loading =
    (requestsLoading || submittedRequestsLoading) &&
    !(requests.length || submittedRequests.length);
  return (
    <Stack>
      <Typography level="title-md">Requests for new computations</Typography>
      <Typography level="body-md">
        This data clean room allows requests for new computations.
        <br />
        Before a computation can be integrated, it needs to be submitted for
        approval by the affected Data Owner(s).
      </Typography>
      {loading ? (
        <Skeleton
          height="46px"
          sx={{ borderRadius: "sm" }}
          variant="rectangular"
        />
      ) : (
        <Fragment>
          <AccordionGroup
            sx={(theme) => ({
              "--List-radius": theme.radius.sm,
              borderRadius: theme.radius.sm,
              [`& .${accordionDetailsClasses.content}`]: {
                boxShadow: (theme) =>
                  `inset 0 1px ${theme.vars.palette.divider}`,
                [`&.${accordionDetailsClasses.expanded}`]: {
                  paddingBlock: "0.75rem",
                },
              },
            })}
            variant="outlined"
          >
            {submittedRequests.length > 0 ? (
              <SubmittedRequestsVarsWrapper
                defaultExpandedComputations={defaultExpandedComputations}
                defaultExpandedRequests={defaultExpandedRequests}
                submittedRequestsIds={submittedRequestsIds}
              >
                {submittedRequests.map(
                  ({ computeNodeId, requestId, commitId }, index) => (
                    <RequestProvider
                      key={requestId}
                      value={{ commitId, computeNodeId, requestId }}
                    >
                      <RequestItem
                        requestId={requestId}
                        {...Object.fromEntries(
                          [
                            index === 0 && "data-first-child",
                            index === submittedRequests.length - 1 &&
                              requests.length === 0 &&
                              "data-last-child",
                          ]
                            .filter(Boolean)
                            .map((k) => [k, ""])
                        )}
                      />
                    </RequestProvider>
                  )
                )}
              </SubmittedRequestsVarsWrapper>
            ) : undefined}
            {requests.length > 0 ? (
              <RequestsVarsWrapper
                defaultExpandedComputations={defaultExpandedComputations}
                defaultExpandedRequests={defaultExpandedRequests}
                requestsIds={requestsIds}
              >
                {requests.map(({ computeNodeId, requestId }, index) => (
                  <RequestProvider
                    key={requestId}
                    value={{ commitId: undefined, computeNodeId, requestId }}
                  >
                    <RequestItem
                      requestId={requestId}
                      {...Object.fromEntries(
                        [
                          index === 0 &&
                            submittedRequests.length === 0 &&
                            "data-first-child",
                          index === requests.length - 1 && "data-last-child",
                        ]
                          .filter(Boolean)
                          .map((k) => [k, ""])
                      )}
                    />
                  </RequestProvider>
                ))}
              </RequestsVarsWrapper>
            ) : undefined}
          </AccordionGroup>
        </Fragment>
      )}
      {hasDevTab && <RequestCreatorPlaceholder />}
    </Stack>
  );
};

export default Requests;
