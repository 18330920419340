import {
  Avatar,
  AvatarGroup,
  List,
  ListItem,
  ListItemDecorator,
  Tooltip,
} from "@mui/joy";

interface DataRoomParticipantAvatarGroupProps {
  participants: { name: string; active?: boolean }[];
  max?: number;
}

const DataRoomParticipantAvatarGroup: React.FC<
  DataRoomParticipantAvatarGroupProps
> = ({ participants = [], max = 4 }) => {
  const participantsToShow = participants.slice(0, max);
  const participantsToHide = participants.slice(max);
  return (
    <AvatarGroup>
      {participantsToShow.map((participant, index) => (
        <Tooltip key={index} placement="top" title={participant.name}>
          <Avatar
            color={participant.active ? "primary" : "neutral"}
            variant={participant.active ? "solid" : "soft"}
          >
            {participant.name?.substring(0, 2).toUpperCase()}
          </Avatar>
        </Tooltip>
      ))}
      {participantsToHide.length > 0 ? (
        <Tooltip
          placement="bottom"
          title={
            <List>
              {participantsToHide.map((participant, index) => (
                <ListItem key={index}>
                  <ListItemDecorator>
                    <Avatar
                      color={participant.active ? "primary" : "neutral"}
                      variant={participant.active ? "solid" : "soft"}
                    >
                      {participant.name?.substring(0, 2).toUpperCase()}
                    </Avatar>
                  </ListItemDecorator>
                  {participant.name}
                </ListItem>
              ))}
            </List>
          }
          variant="outlined"
        >
          <Avatar>+{participantsToHide.length}</Avatar>
        </Tooltip>
      ) : null}
    </AvatarGroup>
  );
};

export default DataRoomParticipantAvatarGroup;
