import {
  Accordion,
  AccordionDetails,
  accordionDetailsClasses,
  AccordionGroup,
  AccordionSummary,
  Box,
  Chip,
  Stack,
  Typography,
} from "@mui/joy";
import { memo } from "react";
import { useRequest, useRequestsVars } from "contexts";
import { ComputeNode } from "features/computeNode";
import RequestActions from "../RequestActions/RequestActions";
import RequestAnalysts from "../RequestAnalysts/RequestAnalysts";
import RequestApprovers from "../RequestApprovers/RequestApprovers";
import SubmittedRequestRebase from "../SubmittedRequestRebase/SubmittedRequestRebase";
import SubmittedRequestSignees from "../SubmittedRequestSignees/SubmittedRequestSignees";
import SubmittedRequestSubmittionStatus from "../SubmittedRequestSubmittionStatus/SubmittedRequestSubmittionStatus";
import useRequestName from "./useRequestName";

interface RequestItemProps {
  requestId: string;
}

const RequestItem: React.FC<RequestItemProps> = memo(
  ({ requestId, ...rest }) => {
    const { checkRequestSelection, context } = useRequestsVars();
    const isRequestSelected = checkRequestSelection(requestId);
    const { computeNodeId } = useRequest();
    const { name } = useRequestName(computeNodeId);
    return (
      <Accordion defaultExpanded={isRequestSelected} {...rest}>
        <AccordionSummary
          slotProps={{
            button: { sx: { flexWrap: "wrap" } },
            indicator: { sx: { flex: 1, order: 1 } },
          }}
        >
          <Typography
            endDecorator={
              <Chip>{context === "draft" ? "Draft" : "Pending"}</Chip>
            }
            level="title-md"
          >
            {name}
          </Typography>
          <Stack direction="row" sx={{ order: 2 }}>
            {context === "submitted" && (
              <SubmittedRequestSubmittionStatus requestId={requestId} />
            )}
            <RequestActions requestId={requestId} />
          </Stack>
          {context === "submitted" ? (
            <Box sx={{ order: 5, width: "100%" }}>
              <SubmittedRequestRebase requestId={requestId} />
            </Box>
          ) : null}
        </AccordionSummary>
        <AccordionDetails>
          <Stack>
            <RequestAnalysts requestId={requestId} />
            {context === "submitted" ? (
              <SubmittedRequestSignees submittedRequestId={requestId} />
            ) : null}
            {context === "draft" ? (
              <RequestApprovers requestId={requestId} />
            ) : null}
            <AccordionGroup
              disableDivider={true}
              sx={(theme) => ({
                "--List-radius": theme.radius.sm,
                borderRadius: theme.radius.sm,
                [`& .${accordionDetailsClasses.content}`]: {
                  boxShadow: (theme) =>
                    `inset 0 1px ${theme.vars.palette.divider}`,
                  [`&.${accordionDetailsClasses.expanded}`]: {
                    paddingBlock: "0.75rem",
                  },
                },
              })}
              variant="outlined"
            >
              <ComputeNode computeNodeId={computeNodeId} />
            </AccordionGroup>
          </Stack>
        </AccordionDetails>
      </Accordion>
    );
  }
);
RequestItem.displayName = "RequestItem";

export default RequestItem;
