import { faAngleDown, faXmark } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  accordionSummaryClasses,
  type AccordionSummaryProps,
  type IconButtonProps,
  type StyleOverrides,
  type Theme,
} from "@mui/joy";
import {
  alertClasses,
  autocompleteClasses,
  buttonClasses,
  dialogActionsClasses,
  dialogContentClasses,
  dialogTitleClasses,
  dividerClasses,
  menuButtonClasses,
  modalDialogClasses,
  selectClasses,
  tabClasses,
} from "@mui/joy";
import { extendTheme, type PaletteRange } from "@mui/joy/styles";

declare module "@mui/joy/styles" {
  interface ColorPalettePropOverrides {
    secondary: true;
    chart: true;
  }
  interface Palette {
    secondary: PaletteRange;
    chart: PaletteRange;
  }
}

// TODO: Figure out module augmentation without copy-pasting this lot
type DqSortableAccordionSummarySlot = "root" | "grip";
interface DqSortableAccordionSummaryProps
  extends Omit<AccordionSummaryProps, "id"> {
  id: string | number;
  slotProps?: AccordionSummaryProps["slotProps"] & {
    grip?: IconButtonProps;
  };
}
interface DqSortableAccordionSummaryOwnerState
  extends DqSortableAccordionSummaryProps {}
declare module "@mui/joy/styles" {
  interface Components {
    DqSortableAccordionSummary?: {
      defaultProps?: Partial<DqSortableAccordionSummaryProps>;
      styleOverrides?: StyleOverrides<
        DqSortableAccordionSummarySlot,
        DqSortableAccordionSummaryOwnerState,
        Theme
      >;
    };
  }
}

const xmark = <FontAwesomeIcon icon={faXmark} />;
const indicator = <FontAwesomeIcon fixedWidth={true} icon={faAngleDown} />;

const indicatorActiveStyles = {
  transform: "none", // NOTE: "rotate(-180deg)" to be used eventually. Split-button's `IconButton` to use `className="...-indicator"` and the same styles?
};

export const joyTheme = extendTheme({
  colorSchemes: {
    light: {
      palette: {
        chart: {
          100: "#B3FFFA",
          200: "#00FFEE",
          300: "#00EBDB",
          400: "#00D6C8",
          50: "#DBFFFD",
          500: "#00BFB3",
          600: "#00ADA2",
          700: "#00998F",
          800: "#008077",
          900: "#005C56",
        },
        primary: {
          100: "#ccfbf1",
          200: "#99f6e4",
          300: "#5eead4",
          400: "#2dd4bf",
          50: "#f0fdfa",
          500: "#00bfb3",
          600: "#0d9488",
          700: "#0f766e",
          800: "#115e59",
          900: "#134e4a",
          solidColor: "black",
        },
        secondary: {
          100: "#EBE5FB",
          200: "#D8CAF7",
          300: "#BDA7F1",
          400: "#9C7BEA",
          50: "#F5F2FD",
          500: "#6732DE",
          600: "#5722D3",
          700: "#4C1EB9",
          800: "#3F199A",
          900: "#2C1169",
          outlinedActiveBg: "var(--joy-palette-secondary-100)",
          outlinedBorder: "var(--joy-palette-secondary-500)",
          outlinedColor: "var(--joy-palette-secondary-700)",
          plainActiveBg: "var(--joy-palette-secondary-100)",
          plainColor: "var(--joy-palette-secondary-700)",
          softActiveBg: "var(--joy-palette-secondary-300)",
          softBg: "var(--joy-palette-secondary-200)",
          softColor: "var(--joy-palette-secondary-800)",
          solidActiveBg: "var(--joy-palette-secondary-500)",
          solidBg: "var(--joy-palette-secondary-400)",
        },
        success: {
          100: "#B3FFFA",
          200: "#00FFEE",
          300: "#00EBDB",
          400: "#00D6C8",
          50: "#DBFFFD",
          500: "#00BFB3",
          600: "#00ADA2",
          700: "#00998F",
          800: "#008077",
          900: "#005C56",
          solidColor: "black",
        },
      },
    },
  },
  components: {
    DqSortableAccordionSummary: {
      defaultProps: {
        slotProps: {
          button: { component: "div" },
          grip: { variant: "outlined" },
        },
      },
      styleOverrides: {
        grip: ({ theme }) => ({
          borderRadius: theme.radius.xs,
          minWidth: "auto",
        }),
        root: ({ theme }) => ({
          [`& .${accordionSummaryClasses.button}`]: {
            padding: theme.spacing(0.5),
          },
        }),
      },
    },
    JoyAccordionSummary: {
      defaultProps: {
        indicator,
      },
      styleOverrides: {
        indicator: {
          [`&.${selectClasses.expanded}`]: {
            ...indicatorActiveStyles,
          },
          transition: "0.2s",
        },
      },
    },
    JoyAutocomplete: {
      defaultProps: {
        clearIcon: xmark,
        noOptionsText: "No more options",
        popupIcon: indicator,
      },
      styleOverrides: {
        input: {
          minWidth: "50%", // NOTE: Up from `30px`
        },
        popupIndicator: {
          [`&.${autocompleteClasses.popupIndicatorOpen}`]: {
            ...indicatorActiveStyles,
          },
          transition: "0.2s",
        },
        root: {
          boxShadow: "none",
        },
      },
    },
    JoyAvatar: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.size === "sm" && {
            fontSize: `calc(var(--Avatar-size, 2rem) * 0.4375)`,
            height: `var(--Avatar-size, 2rem)`,
            width: `var(--Avatar-size, 2rem)`, // default as 14px
          }),
          ...(ownerState.size === "md" && {
            fontSize: `calc(var(--Avatar-size, 2.25rem) * 0.4444)`,
            height: `var(--Avatar-size, 2.25rem)`,
            width: `var(--Avatar-size, 2.25rem)`, // default as 16px
          }),
          ...(ownerState.size === "lg" && {
            fontSize: `calc(var(--Avatar-size, 2.5rem) * 0.45)`,
            height: `var(--Avatar-size, 2.5rem)`,
            width: `var(--Avatar-size, 2.5rem)`, // default as 18px
          }),
        }),
      },
    },
    JoyButton: {
      defaultProps: {
        color: "neutral",
        variant: "plain",
      },
    },
    JoyChip: {
      defaultProps: {
        size: "lg",
      },
    },
    JoyChipDelete: {
      defaultProps: {
        children: xmark,
      },
    },
    JoyCircularProgress: {
      defaultProps: {
        thickness: 1,
      },
    },
    JoyDialogActions: {
      defaultProps: {
        orientation: "horizontal",
      },
      styleOverrides: {
        root: {
          [`& > ${buttonClasses.root}:only-child`]: { flex: "0 0 auto" },
          justifyContent: "space-between",
        },
      },
    },
    JoyDrawer: {
      styleOverrides: {
        content: {
          "& > form": {
            flex: 1,
            overflow: "hidden",
          },
          [`& .${modalDialogClasses.root}`]: {
            height: "100%",
            maxHeight: "100%",
            maxWidth: "100%",
            minWidth: "100%",
            position: "relative",
            width: "100%",
          },
        },
      },
    },
    JoyFormHelperText: {
      styleOverrides: {
        root: {
          "&:empty": {
            display: "none",
          },
        },
      },
    },
    JoyInput: {
      styleOverrides: {
        root: {
          boxShadow: "none",
        },
      },
    },
    JoyMenuButton: {
      defaultProps: {
        endDecorator: <FontAwesomeIcon icon={faAngleDown} />,
      },
      styleOverrides: {
        // @ts-expect-error // NOTE: The prop exists, but the type definition is missing
        endDecorator: {
          color: "var(--Icon-color)",
          fontSize: "1.14285714286em",
          marginInlineEnd: "calc(var(--Button-paddingInline, 1rem) / -4)",
          transition: "0.2s",
        },
        root: {
          [`&[aria-expanded="true"] > .${menuButtonClasses.endDecorator}`]: {
            ...indicatorActiveStyles,
          },
        },
        startDecorator: {
          color: "var(--Icon-color)", // NOTE: Should probably be applied to `FontAwesomeIcon`'s `svg` element the same way as it is applied to the `SvgIcon` component
          fontSize: "var(--Icon-fontSize)", // NOTE: Should probably be applied to `FontAwesomeIcon`'s `svg` element the same way as it is applied to the `SvgIcon` component
        },
      },
    },
    JoyModalDialog: {
      defaultProps: {
        minWidth: 400,
        variant: "plain",
      },
      styleOverrides: {
        root: ({ theme }) => ({
          /* 
            NOTE: ModalDialog considers itself a Card, thus applies Card padding.
            That CSS variable can be used to adjust the paddings of the title, content, and actions.
            The content is expanded to fill the entire width using the same technique as `CardOverflow` does.
            This allows for better scrollbar positioning and avoids cutting off overflowing shadows.
          */
          [`& > .${dialogActionsClasses.root}`]: {
            paddingTop: "var(--Card-padding)",
          },
          [`& > .${dialogContentClasses.root}`]: {
            marginInline: "calc(var(--Card-padding) * -1)",
            padding: "var(--Card-padding)",
          },
          [`& > .${dialogTitleClasses.root}`]: {
            paddingBottom: "var(--Card-padding)",
          },
          [`& > .${dialogTitleClasses.root} + ${dialogContentClasses.root}`]: {
            paddingTop: 0,
          },
          [`& > .${dividerClasses.root} + .${dividerClasses.root}`]: {
            display: "none", // NOTE: Regardless of how many dividers are rendered, only the first one is displayed
          },
          [`& > .${alertClasses.root}`]: {
            borderRadius: 0, // NOTE: Since the `Alert` is stretched, the border radius should be removed
            marginInline: "var(--Divider-inset)", // NOTE: Stretching `Alert`s to the full width of the dialog
          },
          "&[role=alertdialog]": {
            [`& > .${dialogContentClasses.root}`]: {
              textAlign: "center",
              textWrap: "balance",
            },
            [`& > .${dialogTitleClasses.root}`]: {
              "& >:not(svg)": {
                color: theme.palette.text.primary, // NOTE: The same effect can be achieved by using the `style={{ color: "initial" }}` prop on the `span` tag
              },
              alignItems: "center",
              flexDirection: "column",
              textAlign: "center",
              textWrap: "balance",
            },
            "--ModalDialog-maxWidth": "400px",
            "--ModalDialog-minWidth": "300px",
          },
          "--Card-padding": "1rem",
          gap: 0,
        }),
      },
    },
    JoyRadio: {
      styleOverrides: {
        action: ({ ownerState }) => ({
          ...(ownerState.overlay &&
            ownerState.checked && {
              border: "2px solid",
              borderColor: "var(--joy-palette-primary-500)",
              inset: -1,
            }),
        }),
        radio: ({ theme, ownerState }) => ({
          ...(ownerState.overlay &&
            ownerState.checked &&
            theme.variants.solid.primary),
          "&:hover": {
            ...(ownerState.overlay &&
              ownerState.checked &&
              theme.variants.solidHover.primary),
          },
        }),
        root: ({ ownerState }) => ({
          ...(ownerState.overlay && { flexShrink: 0 }),
        }),
      },
    },
    JoySelect: {
      defaultProps: {
        indicator,
        slotProps: {
          button: ({ value }) => ({
            ...((value === null ||
              value === undefined ||
              value === "" ||
              (Array.isArray(value) && value.length === 0)) && {
              sx: {
                opacity: "var(--Select-placeholderOpacity)", // NOTE: Fixing opacity for multi-selects
              },
            }),
          }),
          listbox: {
            onKeyDown: (event) => {
              /*
                NOTE:
                Not sure if only Escape should not be propagated by default,
                but currently it is the only key that causes problems
                (e.g. closing the dialog along with the select's menu).
              */
              if (event.key === "Escape") {
                event.stopPropagation();
              }
            },
            placement: "bottom-start",
          },
        },
      },
      styleOverrides: {
        indicator: {
          [`&.${selectClasses.expanded}`]: {
            ...indicatorActiveStyles,
          },
          transition: "0.2s",
        },
        root: ({ theme, ownerState }) => ({
          '&:active, &[aria-pressed="true"]':
            theme.variants[`${ownerState.variant!}Active`]?.[ownerState.color!],
          boxShadow: "none",
        }),
      },
    },
    JoyStack: {
      defaultProps: {
        spacing: 1,
        useFlexGap: true,
      },
    },
    JoyTab: {
      defaultProps: {
        color: "neutral",
        indicatorInset: true,
      },
    },
    JoyTabList: {
      styleOverrides: {
        root: {
          [`& .${tabClasses.root}`]: {
            backgroundColor: "transparent",
            [`&.${tabClasses.selected}`]: {
              backgroundColor: "transparent",
            },
          },
        },
      },
    },
    JoyTabs: {
      styleOverrides: {
        root: {
          backgroundColor: "var(--joy-palette-background-body)",
        },
      },
    },
    JoyTextarea: {
      styleOverrides: {
        root: {
          boxShadow: "none",
        },
      },
    },
    JoyTooltip: {
      defaultProps: {
        placement: "top",
      },
      styleOverrides: {
        root: {
          maxHeight: 200,
          maxWidth: 400,
          overflowY: "auto",
        },
      },
    },
  },
});
