import { SANITIZE_IDENTIFIER_INPUT } from "constants/index";
import { DatasetSchemaExtractionDialog, DqTable } from "@decentriq/components";
import { type VersionedSchema } from "@decentriq/components/dist/components/DatasetUploader/types";
import {
  ColumnDataType,
  TableColumnFormatType,
  TableColumnHashingAlgorithm,
} from "@decentriq/graphql/dist/types";
import { testIds } from "@decentriq/utils";
import {
  faFileImport,
  faPlus,
  faTrashCan,
  faXmark,
} from "@fortawesome/pro-light-svg-icons";
import {
  faLink as farLink,
  faPlus as farPlus,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Alert,
  Button,
  Checkbox,
  Chip,
  ChipDelete,
  FormControl,
  FormHelperText,
  IconButton,
  Input,
  Option,
  Select,
  Tooltip,
} from "@mui/joy";
import { type MRT_ColumnDef, type MRT_Row } from "material-react-table";
import {
  type ChangeEventHandler,
  Fragment,
  type KeyboardEventHandler,
  memo,
  useCallback,
  useMemo,
  useState,
} from "react";
import { useDataRoom } from "contexts";
import { useDataNodeActions } from "features/dataNodes/containers/DataNodes/DataNodesActionsWrapper";
import {
  castFormatTypeToPrimitiveType,
  castPrimitiveTypeToFormatType,
} from "models";
import { type DataRoomTableColumn } from "models";
import { chainPromises } from "utils";
import { isValidIdentifier, sanitizeIdentifier } from "utils/validation";
import {
  dataRoomTableFormatTypeOptions,
  dataRoomTablePrimitiveTypeOptions,
} from "./DataNodeConstructorModels";
import { useDataNodeConstructorParams } from "./DataNodeConstructorParamsWrapper";

const arrayMove = <T,>(array: T[], from: number, to: number): T[] => {
  const newArray = array.slice();
  newArray.splice(
    to < 0 ? newArray.length + to : to,
    0,
    newArray.splice(from, 1)[0]
  );
  return newArray;
};

interface TableNodeColumnConstructorProps {
  tableNodeId: string;
  columns: DataRoomTableColumn[];
  columnsOrder: string[];
  isLoading: boolean;
  onChangeOutcome?: (columnAdded?: boolean, columnId?: string) => void;
  uniqueColumnIds?: string[][];
  updateUniqueColumnIds?: (uniqueColumnIds: string[][]) => void;
}

export const TableNodeColumnConstructor: React.FC<TableNodeColumnConstructorProps> =
  memo(
    ({
      columns,
      columnsOrder,
      isLoading,
      onChangeOutcome,
      tableNodeId,
      uniqueColumnIds,
      updateUniqueColumnIds,
    }) => {
      const { isPublished } = useDataRoom();
      const {
        handleTableColumnDelete,
        handleTableColumnDataTypeUpdate,
        handleTableColumnHashWithUpdate,
        handleTableColumnNameUpdate,
        handleTableColumnNullableUpdate,
      } = useDataNodeActions();
      const { readOnly } = useDataNodeConstructorParams();
      const { handleTableColumnCreate, handleTableColumnsOrderUpdate } =
        useDataNodeActions();
      const [value, setValue] = useState("");
      const error = useMemo(() => {
        return value.trim().length > 0
          ? !isValidIdentifier(value)
            ? "Identifiers should begin with a letter, not end in an underscore, and should contain only alphanumeric characters or spaces"
            : columns.some(({ name }) => name === value)
              ? "Column name must be unique"
              : undefined
          : undefined;
      }, [columns, value]);
      const onChange = useCallback<
        ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
      >((event) => {
        setValue(event.target.value);
      }, []);
      const onKeyDown = useCallback<
        KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement>
      >(
        (event) => {
          if (event.key === "Enter" && value.length > 0 && !error) {
            handleTableColumnCreate(tableNodeId, {
              formatType: TableColumnFormatType.String,
              name: value,
              nullable: false,
              primitiveType: ColumnDataType.Text,
            }).then((response) =>
              onChangeOutcome?.(
                true,
                response?.data?.draftTableLeafNode.addColumn.id
              )
            );
            setValue("");
          }
        },
        [error, handleTableColumnCreate, onChangeOutcome, tableNodeId, value]
      );
      const [
        datasetSchemaExtractionDialogOpen,
        setDatasetSchemaExtractionDialogOpen,
      ] = useState<boolean>(false);
      const replaceWithColumns = useCallback(
        async (newColumns: VersionedSchema["columns"]) => {
          await Promise.all(
            columns.map(({ id }: { id: string }) => handleTableColumnDelete(id))
          );
          const creationResult = await chainPromises(
            newColumns,
            ({ name, nullable, formatType, hashWith }) =>
              handleTableColumnCreate(tableNodeId, {
                formatType,
                hashWith,
                name,
                nullable,
                primitiveType: castFormatTypeToPrimitiveType(formatType),
              })
          );
          const columnsOrder = creationResult.results
            .map(
              ({ result }) => result?.data?.draftTableLeafNode?.addColumn?.id
            )
            .filter((id) => id !== undefined) as string[];
          await handleTableColumnsOrderUpdate({
            columnsOrder,
            id: tableNodeId,
          });
        },
        [
          columns,
          handleTableColumnsOrderUpdate,
          tableNodeId,
          handleTableColumnDelete,
          handleTableColumnCreate,
        ]
      );
      const onOutcomeDialogOpen = () => onChangeOutcome?.();
      const tableData = [...columns].sort(({ id: aId }, { id: bId }) => {
        const aIndex = columnsOrder.indexOf(aId);
        const bIndex = columnsOrder.indexOf(bId);
        return (
          (aIndex > -1 ? aIndex : Infinity) - (bIndex > -1 ? bIndex : Infinity)
        );
      });
      let uniqueHeaders: MRT_ColumnDef<DataRoomTableColumn>[] = [];
      uniqueColumnIds?.forEach((indexes, uniquenessIndex) => {
        columns.forEach((column, columnIndex) => {
          tableData[columnIndex] = {
            ...tableData[columnIndex],
            [`unique_constraint_${uniquenessIndex}`]: indexes.includes(
              column.id
            ),
          };
        });
        uniqueHeaders.push({
          Cell: ({ row, cell }) => {
            return (
              <Checkbox
                checked={cell.getValue<boolean>()}
                checkedIcon={<FontAwesomeIcon icon={farPlus} />}
                disabled={readOnly}
                onChange={(event) => {
                  if (updateUniqueColumnIds) {
                    const newUniqueColumnIds = [...uniqueColumnIds];
                    if (event.target.checked) {
                      newUniqueColumnIds[uniquenessIndex] = [
                        ...new Set([...indexes, row?.original?.id]),
                      ];
                    } else {
                      newUniqueColumnIds[uniquenessIndex] = newUniqueColumnIds[
                        uniquenessIndex
                      ].filter((id) => id !== row?.original?.id);
                    }
                    updateUniqueColumnIds(
                      newUniqueColumnIds.filter((ids) => ids.length !== 0)
                    );
                  }
                }}
                sx={{ zIndex: 5 }}
              />
            );
          },
          Header: () => {
            return (
              <Chip
                endDecorator={
                  !readOnly && updateUniqueColumnIds ? (
                    <ChipDelete
                      onDelete={() => {
                        updateUniqueColumnIds(
                          uniqueColumnIds?.filter(
                            (_, index: number) => index !== uniquenessIndex
                          ) || []
                        );
                      }}
                    />
                  ) : null
                }
                sx={{ alignItems: "center", display: "flex" }}
                variant="plain"
              >
                <Tooltip title="Unique constraint requires rows to have unique values in the selected columns in order for dataset provision to succeed.">
                  <FontAwesomeIcon icon={farLink} />
                </Tooltip>
              </Chip>
            );
          },
          accessorKey: `unique_constraint_${uniquenessIndex}`,
          enableResizing: false,
          grow: false,
          header: "",
          id: `unique_constraint_${uniquenessIndex}`,
          muiTableBodyCellProps: ({
            row: { index },
            table: { getRowCount },
          }) => ({
            align: "center",
            sx: {
              "&::before": {
                background: "#cdd7e1",
                content: '""',
                height:
                  index === 0 || index === getRowCount() - 1 ? "50%" : "100%",
                left: "calc(50% - 1px / 2)",
                position: "absolute",
                top: index === 0 ? "50%" : 0,
                width: "1px",
                zIndex: -1,
              },
            },
          }),
          muiTableHeadCellProps: { align: "center" },
          size: 66,
        });
      });
      if (!readOnly && updateUniqueColumnIds) {
        uniqueHeaders.push({
          Cell: ({ row, cell }) => {
            return (
              <Tooltip title="Create unique constraint">
                <IconButton
                  onClick={(event) => {
                    const newUniqueColumnIds = [...(uniqueColumnIds || [])];
                    newUniqueColumnIds.push([row?.original?.id]);
                    updateUniqueColumnIds(newUniqueColumnIds);
                  }}
                  sx={{
                    borderRadius: "0.25rem",
                    minHeight: "auto",
                    minWidth: "auto",
                    padding: "1px",
                    zIndex: 5,
                  }}
                  variant="outlined"
                >
                  <FontAwesomeIcon fixedWidth={true} icon={faPlus} />
                </IconButton>
              </Tooltip>
            );
          },
          Header: () => {
            return (
              <Tooltip title="Unique constraint requires rows to have unique values in the selected columns in order for dataset provision to succeed.">
                <FontAwesomeIcon icon={farLink} />
              </Tooltip>
            );
          },
          accessorKey: "add_unique_constraint",
          enableResizing: false,
          grow: false,
          header: "",
          id: "add_unique_constraint",
          muiTableBodyCellProps: { align: "center" },
          muiTableHeadCellProps: { align: "center" },
          size: 36,
        });
      }
      const tableColumns: MRT_ColumnDef<DataRoomTableColumn>[] = [
        {
          Cell: ({ cell, row }) => {
            const { id } = row;
            const name = cell.getValue<string>();
            return (
              <Input
                disabled={readOnly}
                fullWidth={true}
                onChange={(event) => {
                  handleTableColumnNameUpdate(
                    id,
                    SANITIZE_IDENTIFIER_INPUT
                      ? sanitizeIdentifier(event.target.value)
                      : event.target.value
                  );
                }}
                sx={{
                  "&:not(.Mui-focused)": {
                    backgroundColor: "transparent",
                  },
                }}
                value={name}
                variant="plain"
              />
            );
          },
          Footer: readOnly ? null : (
            <FormControl error={Boolean(error)} sx={{ flex: 1 }}>
              <Input
                autoFocus={true}
                data-testid={
                  testIds.dataNode.tableColumnConstructor.tableColumn
                }
                endDecorator={
                  value && !error ? (
                    <Tooltip title="Add">
                      <IconButton>
                        <FontAwesomeIcon icon={faPlus} />
                      </IconButton>
                    </Tooltip>
                  ) : undefined
                }
                onChange={onChange}
                onKeyDown={onKeyDown}
                placeholder="Add column…"
                sx={{
                  "&:not(.Mui-focused)": {
                    backgroundColor: "transparent",
                  },
                }}
                value={value}
                variant="plain"
              />
              <FormHelperText>{error}</FormHelperText>
            </FormControl>
          ),
          accessorKey: "name",
          header: "Column name",
          id: "name",
          muiTableHeadCellProps: { style: { paddingLeft: "12px" } },
        },
        ...uniqueHeaders,
        {
          Cell: ({ row }) => {
            const { id } = row;
            const { formatType, primitiveType } = row.original;
            return (
              <Select
                disabled={readOnly}
                onChange={(event, value) => {
                  if (!isPublished) {
                    const formatType = value as TableColumnFormatType;
                    const primitiveType =
                      castFormatTypeToPrimitiveType(formatType);
                    handleTableColumnDataTypeUpdate(
                      id,
                      primitiveType,
                      formatType
                    ).then(onOutcomeDialogOpen);
                  }
                }}
                slotProps={{ listbox: { variant: "outlined" } }}
                sx={{ backgroundColor: "transparent" }}
                value={
                  !isPublished
                    ? formatType || castPrimitiveTypeToFormatType(primitiveType)
                    : formatType || primitiveType
                }
                variant="plain"
              >
                {!isPublished || (isPublished && formatType)
                  ? dataRoomTableFormatTypeOptions?.map(
                      ({ label, value }, index) => (
                        <Option key={index} value={value}>
                          {label}
                        </Option>
                      )
                    )
                  : dataRoomTablePrimitiveTypeOptions?.map(
                      ({ label, value }, index) => (
                        <Option key={index} value={value}>
                          {label}
                        </Option>
                      )
                    )}
              </Select>
            );
          },
          accessorKey: "primitiveType",
          header: "Data type",
          id: "primitiveType",
          muiTableHeadCellProps: { style: { paddingLeft: "12px" } },
        },
        {
          Cell: ({ cell, row }) => {
            const { id } = row;
            const hashWith = cell.getValue<string>();
            return (
              <Checkbox
                checked={hashWith === TableColumnHashingAlgorithm.Sha256Hex}
                disabled={readOnly}
                onChange={(event) => {
                  handleTableColumnHashWithUpdate(
                    id,
                    event.target.checked
                  ).then(onOutcomeDialogOpen);
                }}
              />
            );
          },
          Header: ({ column }) => {
            return (
              <Tooltip title="Check this box to require values in this column to be a valid SHA256 hash. When provisioning a dataset, there will be an option to hash values automatically if they are not yet hashed.">
                <span>{column.columnDef.header}</span>
              </Tooltip>
            );
          },
          accessorKey: "hashWith",
          header: "Hashed values",
          id: "hashWith",
          muiTableBodyCellProps: { align: "center" },
          muiTableHeadCellProps: { align: "center" },
        },
        {
          Cell: ({ cell, row }) => {
            const { id } = row;
            const nullable = cell.getValue<boolean>();
            return (
              <Checkbox
                checked={nullable}
                disabled={readOnly}
                onChange={(event) => {
                  handleTableColumnNullableUpdate(
                    id,
                    event.target.checked
                  ).then(onOutcomeDialogOpen);
                }}
              />
            );
          },
          accessorKey: "nullable",
          header: "Allow empty values",
          id: "nullable",
          muiTableBodyCellProps: { align: "center" },
          muiTableHeadCellProps: { align: "center" },
        },
      ];
      return columns.length === 0 && readOnly ? (
        <Alert>This table has no columns</Alert>
      ) : (
        <Fragment>
          <DqTable
            columns={tableColumns}
            data={tableData}
            enableRowActions={!readOnly}
            enableRowOrdering={!readOnly}
            enableStickyFooter={true}
            enableTableFooter={!readOnly}
            enableTopToolbar={!readOnly}
            getRowId={(row) => row.id}
            muiRowDragHandleProps={({ table }) => ({
              onDragEnd: () => {
                const { draggingRow, hoveredRow } = table.getState();
                if (hoveredRow && draggingRow) {
                  const oldIndex = columnsOrder.indexOf(draggingRow.id);
                  const newIndex = columnsOrder.indexOf(
                    (hoveredRow as MRT_Row<DataRoomTableColumn>).id
                  );
                  const newColumnsOrder = arrayMove(
                    columnsOrder,
                    oldIndex,
                    newIndex
                  );
                  handleTableColumnsOrderUpdate({
                    columnsOrder: newColumnsOrder,
                    id: tableNodeId,
                  }).then(() => onChangeOutcome?.());
                }
              },
            })}
            muiTableBodyCellProps={{ sx: { p: 0 } }}
            muiTableBodyRowProps={{
              sx: { "&:not(:hover)": { zIndex: 1 } },
            }}
            muiTableContainerProps={
              readOnly
                ? {
                    sx: {
                      " --variant-plainDisabledColor": "--variant-plainColor",
                      "--variant-solidDisabledColor": " --variant-solidColor",
                    },
                  }
                : undefined
            }
            muiTableFooterCellProps={{ sx: { p: 0 } }}
            muiTableFooterProps={{
              sx: { "& .MuiTableCell-footer": { fontWeight: "inherit" } },
            }}
            muiTableProps={{
              style: {
                "--col-mrt_row_actions-size": 36,
                "--col-mrt_row_drag-size": 36,
                "--header-mrt_row_actions-size": 36,
                "--header-mrt_row_drag-size": 36,
              } as React.CSSProperties,
            }}
            muiTopToolbarProps={{
              sx: { "& > *": { padding: "0 !important" }, p: 0 },
            }}
            renderRowActions={({ row }) => {
              const { id } = row;
              return [
                <Tooltip key="delete" title="Delete">
                  <IconButton
                    onClick={() =>
                      handleTableColumnDelete(id).then(onOutcomeDialogOpen)
                    }
                    sx={{ "--focus-outline-offset": "-2px" }}
                  >
                    <FontAwesomeIcon icon={faTrashCan} />
                  </IconButton>
                </Tooltip>,
              ];
            }}
            renderTopToolbarCustomActions={() => (
              <Button
                onClick={() => setDatasetSchemaExtractionDialogOpen(true)}
                slotProps={{
                  startDecorator: { sx: { fontSize: "1rem" } },
                }}
                startDecorator={
                  <FontAwesomeIcon fixedWidth={true} icon={faFileImport} />
                }
                sx={{ borderRadius: 0, height: 40 }}
              >
                Import schema from dataset
              </Button>
            )}
            state={{
              columnOrder: [
                "mrt-row-drag",
                ...tableColumns
                  .map(({ id }) => id)
                  .filter((id) => typeof id === "string"),
                "mrt-row-actions",
              ],
              isLoading,
              showLoadingOverlay: false,
              showProgressBars: false,
              showSkeletons: isLoading,
            }}
          />
          <DatasetSchemaExtractionDialog
            DatasetUploaderProps={{
              OkayButtonProps: {
                onClick: (_, schema) => {
                  replaceWithColumns?.(schema?.columns || []);
                  setDatasetSchemaExtractionDialogOpen(false);
                },
              },
              schema: undefined,
            }}
            DialogTitleChildren={
              <>
                <span>Import schema</span>
                <IconButton
                  onClick={() => setDatasetSchemaExtractionDialogOpen(false)}
                  sx={{ fontSize: "1.25rem", p: 0.5, width: "2rem" }}
                >
                  <FontAwesomeIcon fixedWidth={true} icon={faXmark} />
                </IconButton>
              </>
            }
            DialogTitleProps={{
              sx: {
                display: "flex",
                justifyContent: "space-between",
              },
            }}
            onClose={() => setDatasetSchemaExtractionDialogOpen(false)}
            open={datasetSchemaExtractionDialogOpen}
          />
        </Fragment>
      );
    }
  );
