import { exceptions } from "@decentriq/utils";
import { faXmark } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Accordion,
  AccordionDetails,
  accordionDetailsClasses,
  AccordionGroup,
  AccordionSummary,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Modal,
  ModalDialog,
  Stack,
  Typography,
} from "@mui/joy";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { JsonEditorField } from "components";
import {
  DataLabDataNodeActions,
  useDataLabContext,
  useDataLabDataNodeActions,
  useDataLabSnackbar,
} from "features/dataLabs";
import {
  DataLabDataNodeType,
  dataLabNodeColumnUniqueness,
  getDataLabDataNodeColumnsMap,
} from "features/dataLabs/models";
import {
  DataNodeConstructorMode,
  DataNodeUploadDataDialog,
} from "features/dataNodes";
import { DataNodeConstructorParamsWrapper } from "features/dataNodes/components/DataNodeConstructor/DataNodeConstructorParamsWrapper";
import { TableNodeColumnConstructor } from "features/dataNodes/components/DataNodeConstructor/TableNodeColumnConstructor";
import {
  type DataIngestionPayload,
  type DatasetIngestionDefinition,
  type FileIngestionDefinition,
} from "features/datasets";
import {
  CommonSnackbarOrigin,
  mapDataRoomErrorToSnackbar,
  useReportError,
} from "hooks";
import { useFeatureFlag } from "utils/featureFlags";

interface DataLabNodeItemProps {
  description?: string;
  title: string;
  collapseContent?: React.ReactNode;
  actions: React.ReactNode;
}

const DataLabNodeItem: React.FC<DataLabNodeItemProps> = ({
  description,
  title,
  collapseContent,
  actions,
  ...rest
}) => {
  return (
    <Accordion {...rest}>
      <AccordionSummary slotProps={{ indicator: { sx: { order: 1 } } }}>
        <Stack alignItems="center" direction="row" flex={1} order={2}>
          <Typography sx={{ flex: 1 }}>{title}</Typography>
          {description ? (
            <Typography component="span" level="body-sm">
              {description}
            </Typography>
          ) : null}
          {actions}
        </Stack>
      </AccordionSummary>
      {collapseContent ? (
        <AccordionDetails>{collapseContent}</AccordionDetails>
      ) : null}
    </Accordion>
  );
};

const DataLabNodes: React.FC = () => {
  const {
    dataLab: { data: dataLab },
    computationError,
    datasetValidationErrors,
  } = useDataLabContext();
  const { enqueueSnackbar } = useDataLabSnackbar();
  const reportError = useReportError();
  const {
    activeDataRoomUpload,
    currentUserEmail,
    handleConnectFromKeychain,
    handleDataDeprovision,
    handleIngestData,
    handleUploadClose,
    setTypeForUpload,
    typeForUpload,
    uploadings,
  } = useDataLabDataNodeActions();
  useEffect(() => {
    datasetValidationErrors.forEach((message, dataNodeType) => {
      enqueueSnackbar(`${dataNodeType} dataset failed validation`, {
        action: (
          <Button
            onClick={() => setValidationReportDialogReportType(dataNodeType)}
          >
            View validation report
          </Button>
        ),
        persist: true,
        variant: "error",
      });
    });
  }, [datasetValidationErrors, enqueueSnackbar]);
  const onDataDeprovision = useCallback(
    (type: DataLabDataNodeType) => async () =>
      await handleDataDeprovision(type),
    [handleDataDeprovision]
  );
  const {
    demographicsDataset,
    embeddingsDataset,
    requireDemographicsDataset,
    requireEmbeddingsDataset,
    requireSegmentsDataset,
    matchingIdFormat,
    matchingIdHashingAlgorithm,
    numEmbeddings,
    segmentsDataset,
    usersDataset,
  } = dataLab!;
  const dataNodes: {
    type: DataLabDataNodeType;
    manifestHash: string | undefined;
    visible: boolean;
  }[] = useMemo(
    () => [
      {
        manifestHash: usersDataset?.manifestHash,
        type: DataLabDataNodeType.matching,
        visible: true,
      },
      {
        manifestHash: segmentsDataset?.manifestHash,
        type: DataLabDataNodeType.segments,
        visible: requireSegmentsDataset,
      },
      {
        manifestHash: demographicsDataset?.manifestHash,
        type: DataLabDataNodeType.demographics,
        visible: requireDemographicsDataset,
      },
      {
        manifestHash: embeddingsDataset?.manifestHash,
        type: DataLabDataNodeType.embeddings,
        visible: requireEmbeddingsDataset,
      },
    ],
    [
      usersDataset?.manifestHash,
      segmentsDataset?.manifestHash,
      demographicsDataset?.manifestHash,
      embeddingsDataset?.manifestHash,
      requireDemographicsDataset,
      requireEmbeddingsDataset,
      requireSegmentsDataset,
    ]
  );
  const dataLabDataNodeColumnsMap = getDataLabDataNodeColumnsMap({
    matchingIdFormat,
    matchingIdHashingAlgorithm,
    numEmbeddings,
  });
  useEffect(() => {
    if (computationError) {
      const [message, options] = mapDataRoomErrorToSnackbar(
        computationError.originalError,
        computationError.message
      );
      enqueueSnackbar(
        `${computationError.message}. ${computationError.message === message ? "" : message}`,
        options
      );
    }
  }, [computationError, enqueueSnackbar]);
  const handleError = useCallback(
    (error: Error) => {
      if (
        error instanceof exceptions.DatasetValidationError &&
        error.hasReport
      ) {
        return;
      }
      reportError(
        {
          details: error.message,
          errorContext: [],
          origin: CommonSnackbarOrigin.DATA_LAB,
        },
        { silent: true }
      );
    },
    [reportError]
  );
  const [
    validationReportDialogReportType,
    setValidationReportDialogReportType,
  ] = useState<DataLabDataNodeType | null>(null);
  const onIngest = useCallback(
    async (
      payload:
        | DataIngestionPayload<DatasetIngestionDefinition>
        | DataIngestionPayload<FileIngestionDefinition>
    ) => {
      // NOTE: This is inconsistent with other similar `onSelect` routines
      if (!typeForUpload) {
        return;
      }
      if (payload.source === "local") {
        return await handleIngestData({
          dataNodeId: typeForUpload,
          schema: payload.schema,
          shouldStoreInKeychain: !!payload.shouldStoreInKeychain,
          uploadResult: payload.uploadResult!,
        });
      }
      if (payload.source === "keychain") {
        return await handleConnectFromKeychain(
          typeForUpload,
          payload.datasetKeychainItem!
        );
      }
    },
    [handleConnectFromKeychain, handleIngestData, typeForUpload]
  );
  const skipDatalabValidation = useFeatureFlag("skip_datalab_validation");
  return (
    <>
      <AccordionGroup
        sx={(theme) => ({
          "--List-radius": theme.radius.sm,
          borderRadius: theme.radius.sm,
          [`& .${accordionDetailsClasses.content}`]: {
            boxShadow: (theme) => `inset 0 1px ${theme.vars.palette.divider}`,
            paddingInline: 0,
            [`&.${accordionDetailsClasses.expanded}`]: {
              padding: "1px 0 0 0",
            },
          },
        })}
        variant="outlined"
      >
        {dataNodes.map(({ type, manifestHash, visible }, index) => {
          if (!visible) {
            return null;
          }
          const key = `${type}-${currentUserEmail}`;
          const columns = dataLabDataNodeColumnsMap.get(type)!;
          const uniqueColumnIds = dataLabNodeColumnUniqueness.get(type)!;
          return (
            <DataLabNodeItem
              actions={
                <DataLabDataNodeActions
                  hasValidationError={datasetValidationErrors.has(type)}
                  id={type}
                  isLoading={
                    uploadings[key]?.isLoading || activeDataRoomUpload === key
                  }
                  manifestHash={manifestHash}
                  onDataDeprovision={onDataDeprovision(type)}
                  onUpload={() => setTypeForUpload(type)}
                  openValidationReport={() =>
                    setValidationReportDialogReportType(type)
                  }
                />
              }
              collapseContent={
                <DataNodeConstructorParamsWrapper
                  mode={DataNodeConstructorMode.ACTION}
                >
                  <TableNodeColumnConstructor
                    columns={columns}
                    columnsOrder={columns.map(({ id }) => id)}
                    isLoading={false}
                    tableNodeId={type}
                    uniqueColumnIds={uniqueColumnIds}
                  />
                </DataNodeConstructorParamsWrapper>
              }
              description={
                datasetValidationErrors.has(type)
                  ? "Validation failed"
                  : undefined
              }
              key={type}
              title={type}
              {...Object.fromEntries(
                [
                  index === 0 && "data-first-child",
                  index ===
                    dataNodes.filter(({ visible }) => visible).length - 1 &&
                    "data-last-child",
                ]
                  .filter(Boolean)
                  .map((k) => [k, ""])
              )}
            />
          );
        })}
      </AccordionGroup>
      <Modal open={validationReportDialogReportType !== null}>
        <ModalDialog>
          <DialogTitle
            sx={{
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <span>
              Validation report for {validationReportDialogReportType} table
            </span>
            <IconButton
              onClick={() => setValidationReportDialogReportType(null)}
            >
              <FontAwesomeIcon fixedWidth={true} icon={faXmark} />
            </IconButton>
          </DialogTitle>
          <Divider />
          <DialogContent>
            <JsonEditorField
              editorOptions={{
                lineNumbers: "off",
                readOnly: true,
                resizable: false,
              }}
              height={400}
              value={
                datasetValidationErrors?.get(
                  validationReportDialogReportType!
                ) || ""
              }
            />
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button onClick={() => setValidationReportDialogReportType(null)}>
              Close
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
      {!!typeForUpload && (
        <DataNodeUploadDataDialog
          columns={dataLabDataNodeColumnsMap.get(typeForUpload)}
          columnsOrder={dataLabDataNodeColumnsMap
            .get(typeForUpload)!
            .map(({ id }) => id)}
          id={typeForUpload}
          name={typeForUpload}
          onClose={handleUploadClose}
          onError={handleError}
          onIngest={onIngest}
          open={!!typeForUpload}
          skipValidation={skipDatalabValidation}
          uniqueColumnIds={dataLabNodeColumnUniqueness.get(typeForUpload)}
        />
      )}
    </>
  );
};

DataLabNodes.displayName = "DataLabNodes";

export default DataLabNodes;
